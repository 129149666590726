import React, { useCallback } from 'react'
import { CircularProgress, TextField } from '@material-ui/core'
import Autocomplete, { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete'
import { useDispatch } from 'react-redux'
import { useAutocompleteResult, useAutocompleteStatus } from './autocomplete-selectors'
import { actionAutocompleteGet } from './autocomplete-actions'
import { debounce } from 'lodash'
import { RequestStatus } from 'common/enums/request-status.enum'
import { TEXT_LOADING, TEXT_NO_RESULTS } from 'translations/keys'
import { tr } from 'translations/translate'

export type AutocompleteAdapterProps<Entity> = {
    label: string
    autoCompleteName: string
    validate?: any
    fetchUrl: (inputText: string) => string
    getOptionSelected: ((option: Entity, value: Entity) => boolean) | undefined
    getOptionLabel: ((option: Entity) => string) | undefined
    renderOption: ((option: Entity, state: AutocompleteRenderOptionState) => React.ReactNode) | undefined
    valueChanged: (value: Entity) => void
    /** if provided, this function return value will be set as input value */
    valueSetter?: (value: Entity) => any
    compare?: (a: Entity, b: Entity) => number
    placeholder?: string
}

export const CubitAutocompleteAdapter: React.FC<AutocompleteAdapterProps<any> & { input: any; meta: any }> = (
    props,
) => {
    const {
        input: { onChange, onBlur, onFocus },
        meta: { touched, error },
        label,
        getOptionSelected,
        getOptionLabel,
        fetchUrl,
        renderOption,
        valueSetter,
        autoCompleteName,
        compare,
        valueChanged,
        placeholder = '',
    } = props

    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)

    const debounced = useCallback(
        debounce<(url: string) => void>((url) => {
            dispatch(actionAutocompleteGet(autoCompleteName, url))
        }, 500),
        [],
    )

    const autocompleteResult = useAutocompleteResult(autoCompleteName) || []
    const requestStatus = useAutocompleteStatus(autoCompleteName) || RequestStatus.Idle

    const isLoading = requestStatus === RequestStatus.Loading

    const options = autocompleteResult

    if (compare) {
        options.sort(compare)
    }

    const handleInputChange = (inputText: string) => {
        debounced(fetchUrl(inputText))
    }

    return (
        <Autocomplete
            clearOnEscape={false}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            options={options}
            onChange={(event: object, selectedItem: any) => {
                const value = selectedItem && valueSetter ? valueSetter(selectedItem) : selectedItem
                valueChanged(selectedItem)
                return onChange(value)
            }}
            open={open}
            onOpen={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            noOptionsText={tr(TEXT_NO_RESULTS)}
            loadingText={tr(TEXT_LOADING)}
            loading={isLoading}
            onFocus={onFocus}
            filterOptions={(options) => options}
            onBlur={() => onBlur()}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={touched && error ? true : false}
                    helperText={touched && error}
                    label={label}
                    placeholder={placeholder}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="filled"
                    onChange={(v) => handleInputChange(v.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
